import axios from "axios";

export default {
    defaultPath: '/Login',
    basename: '',
    layout: 'vertical',
    preLayout: null,
    collapseMenu: false,
    layoutType: 'menu-light',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)',
    layout6BackSize: '',
};

let backendURL = ['https://tentopos-test.cloudjiffy.net', 'https://tentopos.fra1-de.cloudjiffy.net'];
// let backendURL = ['http://localhost:5008', 'https://tentopos.fra1-de.cloudjiffy.net'];
let primaryURL = 'https://tentopos-test.cloudjiffy.net';
// let primaryURL = 'http://localhost:5008';

let activeURL = JSON.parse(localStorage.getItem('api'));

export let RunningServerURL = activeURL && (activeURL === 'https://tentopos-test.cloudjiffy.net' || activeURL === 'https://tentopos.fra1-de.cloudjiffy.net') ? activeURL : 'https://tentopos-test.cloudjiffy.net';

let receivedResponse = true;

export let checkActiveBackendServer = async () => {
    await axios.get(`${RunningServerURL}/admin_users_api`).then((response) => {
        receivedResponse = true;
        localStorage.setItem('api', JSON.stringify(RunningServerURL));
    }).catch((err) => {
        receivedResponse = false;
    });

    for (let URL of backendURL) {
        if (!receivedResponse) {
            await axios.get(`${URL}/admin_users_api`).then((response) => {
                if (response && !receivedResponse) {
                    RunningServerURL = URL;
                    receivedResponse = true;
                    localStorage.setItem('api', JSON.stringify(RunningServerURL));
                    window.dispatchEvent(new Event('storageChange'));
                }
            }).catch((err) => {
                console.log('*************not working**********', URL, err);
            });
        }
    }
};

let checkActiveUrl = () => {
    /* eslint-disable no-restricted-globals */
    alert('Sorry for the inconvenience, Network problem switching to the active server');
    location.reload();
};

window.addEventListener('storageChange', checkActiveUrl);

let checkPrimaryServer = async () => {
    try {
        if (RunningServerURL !== primaryURL) {
            await axios.get(`${primaryURL}/admin_users_api`).then((response) => {
                if (response.data) {
                    RunningServerURL = primaryURL;
                    localStorage.setItem('api', JSON.stringify(RunningServerURL));
                    window.dispatchEvent(new Event('storageChange'));
                }
            }).catch((err) => {
                console.log('err', err);
            });
        }
    } catch (err) {
        console.log(err);
    }
};

// Initial check on page load
(async () => {
    try {
        await axios.get(`${primaryURL}/admin_users_api`);
        RunningServerURL = primaryURL;
        localStorage.setItem('api', JSON.stringify(RunningServerURL));
    } catch (err) {
        console.log('Primary server is down on initial load, checking active backend server.');
        checkActiveBackendServer().then(() => {
            if (!receivedResponse) {
                alert('Sorry for the inconvenience, Network problem switching to the active server');
                location.reload();
            }
        });
    }
})();

setInterval(() => {
    checkPrimaryServer();
    checkActiveBackendServer();
}, 5000);
