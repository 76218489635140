import React from 'react';

const Login = React.lazy(() => import('./BillingApp_Components/Authentication/SignIn/SignIn1'));
const CashierPage=React.lazy(() => import('./BillingApp_Components/Billingmanagement/CashierPage'));

const route = [
    { path: '/Login', exact: true, name: 'Login', component: Login },
    { path: '/', exact: true, name: 'Login', component: Login },
    { path: '/Cashier/Cashier', exact: true, name: 'Dashboard', component:CashierPage },


];

export default route;